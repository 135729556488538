import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const AtSignIcon = ({ style, className }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={style}
    className={className}
  >
    <rect width="16" height="16" fill="white" fillOpacity="0" />
    <path
      d="M8.15 0.99998C4.21 0.99998 1 4.20998 1 8.13998C1 9.61998 1.45 10.99 2.23 12.13C3.51 14.03 5.69 15.28 8.15 15.28C9.11 15.28 10.05 15.09 10.93 14.72C11.18 14.61 11.3 14.32 11.2 14.06C11.09 13.81 10.8 13.69 10.54 13.79C10.32 13.88 10.09 13.96 9.86 14.02C9.6 14.1 9.34 14.16 9.07 14.2C9.04 14.2 9.01 14.2 8.98 14.21C8.71 14.25 8.43 14.27 8.15 14.27C6.24 14.27 4.54 13.4 3.41 12.04C2.53 10.98 2.01 9.61998 2 8.11998C2 4.74998 4.76 1.98998 8.15 1.98998C11.54 1.98998 14.28 4.74998 14.28 8.12998C14.28 8.37998 14.26 8.62998 14.23 8.87998C14.06 9.95998 13.33 10.69 12.74 10.75C12.07 10.82 11.7 10.06 11.54 9.58998C11.42 9.25998 11.38 8.64998 11.39 8.12998C11.39 6.33998 9.93 4.87998 8.15 4.87998C6.37 4.87998 4.9 6.33998 4.9 8.12998C4.9 9.91998 6.36 11.37 8.15 11.37C9.18 11.37 10.1 10.87 10.69 10.12C11.22 11.41 12.04 11.71 12.56 11.74C12.6 11.74 12.64 11.74 12.67 11.74C14.08 11.74 14.91 10.34 15.18 9.11998C15.25 8.78998 15.29 8.44998 15.29 8.11998C15.29 4.18998 12.09 0.97998 8.16 0.97998L8.15 0.99998ZM8.15 10.39C6.91 10.39 5.9 9.37998 5.9 8.14998C5.9 6.91998 6.91 5.89998 8.15 5.89998C9.39 5.89998 10.39 6.90998 10.39 8.14998C10.39 9.38998 9.38 10.39 8.15 10.39Z"
      fill="currentColor"
    />
  </svg>
);

export default AtSignIcon;
