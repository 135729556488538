"use client";

import React, { useEffect, useRef, useState } from "react";
import ChatBoxEditor from "@/app/components/ChatBox/ChatBoxEditor";
import SimilarThreadsSelector from "@/app/components/SimilarThreadsSelector";
import {
  FilePreview,
  SelectedFile,
} from "@/app/components/ChatBox/FilePreview";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/app/components/Popover";
import TypingIndicator from "@/app/components/TypingIndicator";
import AtSignIcon from "@/app/components/icons/AtSignIcon";
import HashtagIcon from "@/app/components/icons/HashtagIcon";
import { useApp } from "@/app/hooks/useApp";
import useChats from "@/app/hooks/useChats";
import useFiles from "@/app/hooks/useFiles";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";
import useThreads from "@/app/hooks/useThreads";
import useTimedCallout from "@/app/hooks/useTimedCallout";
import { useAppStore } from "@/app/store";
import { StructThread } from "@/app/types/Thread.type";
import { extractChannelIdsFromMessage } from "@/app/utils/stringUtils";
import Picker from "@emoji-mart/react";
import * as Collapsible from "@radix-ui/react-collapsible";
import {
  Cross2Icon,
  FaceIcon,
  InfoCircledIcon,
  PaperPlaneIcon,
  LightningBoltIcon,
  UploadIcon,
} from "@radix-ui/react-icons";
import {
  Box,
  Button,
  Callout,
  Flex,
  IconButton,
  Link,
  Text,
} from "@radix-ui/themes";
import debounce from "lodash.debounce";
import { useTheme } from "next-themes";
import styles from "./ChatBox.module.scss";
import {
  CHAT_VIEW_PLACEHOLDER,
  ENABLE_CHAT_HTTP_RESPONSE_INSERT,
  EntityIDMap,
  FEED_VIEW_PLACEHOLDER,
  SLACK_LEVEL_3_PERMISSIONS,
} from "@/app/constants";
import { joinManyThreadsData } from "@/app/utils/dataUtils";
import ReplyToChatPreview from "@/app/components/ReplyToChatPreview";
import { useParser } from "@/app/hooks/useParser";
import { ChatReply } from "@/app/store/Chats.store";
import ChatActions from "../BulkActionsToolbar/ChatActions";
import { sendTypingStatusAPI } from "@/app/services/misc";
import useThrottle from "@/app/hooks/useThrottle";

const ACCEPTED_FILE_TYPES = [
  "image/*",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv",
  "application/json",
  "application/postscript",
  "image/x-illustrator",
  "application/zip",
  "audio/wav",
  "audio/x-wav",
  "audio/mpeg",
].join(", ");
const TYPING_INDICATOR_DELAY = 5000;

const ChatBox = () => {
  const { currentPath, navigateToPath } = useShallowNavigation();
  const isFeedView = Boolean(!currentPath);
  const { featureToggles } = useApp();
  const { createChatMessage } = useChats();
  const { createThread, fetchSimilarThreads } = useThreads();
  const [isCalloutVisible, showCallout] = useTimedCallout(false, 3000);

  const similarThreadsById = useAppStore((state) => state.similarThreadsById);
  const typingUsersByThreadId = useAppStore(
    (state) => state.typingUsersByThreadId,
  );
  const setLastChatInThreadInEditMode = useAppStore(
    (state) => state.setLastChatInThreadInEditMode,
  );
  const getChatBoxCommands = useAppStore((state) => state.getChatBoxCommands);
  const isAuthenticatedWithCorrectOrg = useAppStore(
    (state) => state.isAuthenticatedWithCorrectOrg,
  );
  const sessionUserId = useAppStore((state) => state.session?.user?.id);
  const organisation = useAppStore((state) => state.organisation);
  const clearChatReply = useAppStore((state) => state.clearChatReply);
  const chatsByThreadId = useAppStore((state) => state.chatsByThreadId);
  const chatReply = useAppStore((state) => state.chatReply);
  const getChatReply = useAppStore((state) => state.getChatReply);

  const typingUserNamesById = useAppStore((state) => state.typingUserNamesById);

  const setChatMessage = useAppStore((state) => state.setChatMessage);
  const insertThreadChatById = useAppStore(
    (state) => state.insertThreadChatById,
  );

  const isSlackOrg = useAppStore(
    (state) => state.organisation?.bits?.org_slack,
  );
  const selectedChats = useAppStore(
    (state) => state.selectedChatsByThreadId?.[currentPath || ""] ?? [],
  );

  const hasSlackAuthPerms = useAppStore(
    (state) => state.session?.user?.bits?.user_slack_auth ?? false,
  );

  const shouldShowSimilarThreads = !!currentPath;

  const throttledSendTypingStatus = useThrottle(
    sendTypingStatusAPI,
    TYPING_INDICATOR_DELAY,
    { leading: true, trailing: false },
  );

  useEffect(() => {
    if (!hasSlackAuthPerms) {
      getChatBoxCommands()?.blur();
    }
  }, [hasSlackAuthPerms]);

  const [chatBoxContent, setChatboxContent] = useState("");
  const [hasSimilarThreadsOpen, setHasSimilarThreadsOpen] =
    useState<boolean>(false);
  const [shouldRefocusInput, setShouldRefocusInput] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [localSimilarThreads, setLocalSimilarThreads] = useState<
    StructThread[]
  >([]);
  const [isColonEmojiPickerVisible, setIsColonEmojiPickerVisible] =
    useState(false);

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const similarThreadsRef = useRef<HTMLDivElement | null>(null);
  const threadIdRef = useRef<StructThread["id"] | null>(null);
  const dropZoneRef = useRef<HTMLDivElement | null>(null);

  const [selectedFiles, setSelectedFiles] = useState<Array<SelectedFile>>([]);

  const uploadedFilesUrlsRef = useRef<string[]>([]);

  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const { uploadFile } = useFiles();

  const similarThreads =
    localSimilarThreads.length > 0
      ? localSimilarThreads
      : threadIdRef.current !== null
      ? similarThreadsById[threadIdRef.current]
      : [];

  const hasSimilarThreads = similarThreads && similarThreads.length > 0;

  const typingUserIds: string[] =
    typingUsersByThreadId[threadIdRef.current || ""] || [];

  const typingUsers = typingUserIds
    .filter((id) => id !== sessionUserId)
    .map((id) => typingUserNamesById?.[id]);

  const { theme } = useTheme();

  const isChatBoxDisabled =
    (isSlackOrg && !hasSlackAuthPerms) || selectedChats.length > 0;

  const prevThreadIdRef = useRef<StructThread["id"] | null>(null);

  const { getCompactComponentsFromChatBlocks } = useParser();

  useEffect(() => {
    if (!chatReply?.id) {
      return;
    }
    getChatBoxCommands()?.focus();
  }, [chatReply?.id]);

  useEffect(() => {
    if (chatReply) {
      clearChatReply();
    }
  }, [currentPath]);

  useEffect(() => {

    if (currentPath && !currentPath.startsWith(EntityIDMap.Thread)) {
      return;
    }

    if (
      (currentPath && prevThreadIdRef.current === null) ||
      (currentPath === null && prevThreadIdRef.current !== null)
    ) {
      if (isChatBoxDisabled) return;
      getChatBoxCommands()?.focus();
    }

    prevThreadIdRef.current = currentPath;
    threadIdRef.current = currentPath;

    // Disabling similar threads to be open by default as the new designs are very intrusive if shown without consent.
    setHasSimilarThreadsOpen(false);
  }, [currentPath, isChatBoxDisabled]);

  useEffect(() => {
    // hasSimilarThreadsOpen
    if (!hasSimilarThreadsOpen && shouldRefocusInput) {
      getChatBoxCommands()?.focus();
      setShouldRefocusInput(false);
    }
  }, [hasSimilarThreadsOpen, shouldRefocusInput]);

  const debouncedFetch = useRef(
    debounce(async (query) => {
      try {
        const { hits } = await fetchSimilarThreads({ query });

        if (!hits || hits.length <= 0) {
          setLocalSimilarThreads([]);
          return;
        }
        const searchSimilarThreads = hits;
        setHasSimilarThreadsOpen(true);
        const joinedSimilarThreads = joinManyThreadsData({
          threads: searchSimilarThreads,
          organisation,
        });

        setLocalSimilarThreads(joinedSimilarThreads);
      } catch (error) {
        console.log(error);
      }
    }, 1000),
  ).current;

  useEffect(() => {
    if (
      isFeedView &&
      chatBoxContent !== ("" as string) &&
      chatBoxContent !== ("@" as string)
    ) {
      debouncedFetch(chatBoxContent);
      return;
    }

    debouncedFetch.cancel();
    setLocalSimilarThreads([]);
  }, [chatBoxContent, isFeedView]);

  useEffect(() => {
    const handleGlobalKeyUp = (e: {
      key: string;
      preventDefault: () => void;
      stopPropagation: () => void;
    }) => {
      // When ESC is pressed and emoji <Picker/> is being shown in the chat
      // box, we close the <Picker/> on ESC key press
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        setHasSimilarThreadsOpen(false);

        setIsEmojiPickerOpen(false);
        getChatBoxCommands()?.blur();
        handleColonEmojiClose(false);
      }
    };

    document.addEventListener("keyup", handleGlobalKeyUp);

    return () => {
      document.removeEventListener("keyup", handleGlobalKeyUp);
    };
  }, []);


  const handleSendMessage = async (message: string) => {
    if (!message) {
      return;
    }

    const messageHasMentions =
      message.includes(`@${EntityIDMap.User}`) ||
      message.includes(`@${EntityIDMap.Channel}`);
    setHasSimilarThreadsOpen(false);
    if (!threadIdRef.current && !messageHasMentions) {
      showCallout();
      return;
    }

    getChatBoxCommands()?.clearContent();
    setChatboxContent("");
    const hasFiles = uploadedFilesUrlsRef.current.length > 0;

    if (!threadIdRef.current) {
      const channelIds = extractChannelIdsFromMessage(message);

      const { thread_id: newThreadId } = await createThread({
        message,
        channelIds,
        attachments: hasFiles ? uploadedFilesUrlsRef.current : undefined,
      });
      uploadedFilesUrlsRef.current = [];
      setSelectedFiles([]);

      navigateToPath(newThreadId);
      return;
    }

    const chatResponse = await createChatMessage({
      message,
      threadId: threadIdRef.current,
      attachments: hasFiles ? uploadedFilesUrlsRef.current : undefined,
      reply_to: getChatReply()?.id,
    });

    if (chatResponse.data && ENABLE_CHAT_HTTP_RESPONSE_INSERT) {
      setChatMessage(threadIdRef.current, chatResponse.data);
      insertThreadChatById(threadIdRef.current, chatResponse.data);
    }

    clearChatReply();

    uploadedFilesUrlsRef.current = [];
    setSelectedFiles([]);
  };

  const handleEmojiClick = ({ native }: { native: string }) => {
    setIsEmojiPickerOpen(false);
    handleColonEmojiClose(true);
    getChatBoxCommands()?.insertContent(native);
    getChatBoxCommands()?.focus();
  };

  const handleColonEmojiClose = (emojiSelected: boolean) => {
    setIsColonEmojiPickerVisible((prevValue) => {
      if (prevValue) {
        if (!emojiSelected) {
          getChatBoxCommands()?.insertCharacter(":");
        }
        getChatBoxCommands()?.focus();
      }
      return false;
    });
  };

  const toggleSimilarThreads = () => {
    if (!hasSimilarThreads) {
      return;
    }
    setHasSimilarThreadsOpen(!hasSimilarThreadsOpen);
  };

  const handleOpenThread = (id: StructThread["id"]) => {
    navigateToPath(id);
  };

  const handleCloseSimilarThreads = () => {
    setHasSimilarThreadsOpen(false);
    setShouldRefocusInput(true);
  };

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  const handleRichTextChange = (content: string) => {
    setChatboxContent(content);

    if (content === "") {
      setHasSimilarThreadsOpen(false);
      debouncedFetch.cancel();
      return;
    }

    if (!threadIdRef.current) {
      return;
    }

    throttledSendTypingStatus(threadIdRef.current);
  };

  const handleInsertCharacter = (character: string) => {
    getChatBoxCommands()?.insertCharacter(character);
    getChatBoxCommands()?.focus();
  };

  const handleArrowUp = () => {
    setHasSimilarThreadsOpen(false);
    if (!sessionUserId) {
      return;
    }

    if (threadIdRef.current) {
      setLastChatInThreadInEditMode(threadIdRef.current, sessionUserId);
    }
  };

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileRemove = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));

    uploadedFilesUrlsRef.current = uploadedFilesUrlsRef.current.filter(
      (_, i) => i !== index,
    );
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleFiles = async (files: File[]) => {
    const previewImages = files.map((file) => {
      const reader = new FileReader();
      const readerPromise = new Promise<{ file: File; url: string }>(
        (resolve) => {
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({ file, url: reader.result as string });
          };
        },
      );
      return readerPromise;
    });

    const loadedPreviews = await Promise.all(previewImages);

    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...loadedPreviews.map((preview) => ({ ...preview, isLoading: true })),
    ]);

    const uploadPromises = files.map((file) =>
      uploadFile(file)
        .then((uploadedFile) => uploadedFile?.handle)
        .catch((error) => {
          console.error("Error uploading file:", error);
          return null;
        }),
    );

    const fileUrls = (await Promise.all(uploadPromises)).filter(
      Boolean,
    ) as string[];

    setSelectedFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (files.includes(file.file)) {
          return { ...file, isLoading: false };
        }
        return file;
      }),
    );

    uploadedFilesUrlsRef.current = [
      ...uploadedFilesUrlsRef.current,
      ...fileUrls,
    ];
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0 && fileInputRef.current) {
      const files = Array.from(e.target.files);
      fileInputRef.current.value = "";
      await handleFiles(files);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const files = Array.from(e.dataTransfer.items)
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile())
        .filter((file): file is File => file !== null);

      await handleFiles(files);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!dropZoneRef.current?.contains(e.relatedTarget as Node | null)) {
      setIsDragging(false);
    }
  };
  

  // feature flag for slack and discord orgs
  // isAuthCurrent org is already checked in for isChatEnabled flag
  if (!featureToggles.isChatEnabled) {
    return null;
  }

  const getRepliedToChatMessage = ({ id, threadId }: ChatReply) => {
    if (!chatReply || !chatsByThreadId[chatReply.threadId]) {
      return;
    }

    return chatsByThreadId[threadId].find((chat) => chat.id === id);
  };

  const repliedToChatMessage = chatReply && getRepliedToChatMessage(chatReply);

  return (
    <div
      ref={dropZoneRef}
      className={styles.chatBox}
      style={{
        opacity: isDragging ? 0.5 : 1,
        position: "relative",
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <ChatActions />
      {isChatBoxDisabled && (
        <Callout.Root
          variant="soft"
          highContrast
          style={{
            width: "max-content",
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -30%)",

            zIndex: 2,
          }}
        >
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            <Flex align="center" gap="2">
              Connect your Slack account to send messages
              <Link href={SLACK_LEVEL_3_PERMISSIONS} target="_blank">
                <Button size="1">Connect</Button>
              </Link>
            </Flex>
          </Callout.Text>
        </Callout.Root>
      )}
      {isDragging && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "var(--slate-a4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            pointerEvents: "none",
          }}
        >
          <Text highContrast>Drag and drop files</Text>
        </div>
      )}
      {isCalloutVisible && (
        <Callout.Root
          variant="soft"
          highContrast
          className={styles.mentionCallout}
        >
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Remember to @mention a <i>channel</i> or a <i>person</i> in your
            message to create a thread.
          </Callout.Text>
        </Callout.Root>
      )}

      <div
        className={[
          styles.textAreaContainer,
          isChatBoxDisabled && styles.disabled,
        ].join(" ")}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <div className={styles.buttonShelf}>
            <div className={styles.chatOptions}>
              <Popover open={isEmojiPickerOpen}>
                <PopoverTrigger asChild>
                  <IconButton
                    variant="ghost"
                    color="gray"
                    onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
                  >
                    <FaceIcon className={styles.icon} />
                  </IconButton>
                </PopoverTrigger>
                <PopoverContent>
                  <Picker
                    theme={theme}
                    onEmojiSelect={handleEmojiClick}
                    onClickOutside={() => setIsEmojiPickerOpen(false)}
                    autoFocus
                  />
                </PopoverContent>
              </Popover>
              <IconButton
                variant="ghost"
                color="gray"
                onClick={() => handleInsertCharacter("@")}
              >
                <AtSignIcon className={styles.icon} />
              </IconButton>
              <IconButton
                variant="ghost"
                color="gray"
                onClick={() => handleInsertCharacter("#")}
              >
                <HashtagIcon className={styles.icon} />
              </IconButton>

              <IconButton
                variant="ghost"
                color="gray"
                className={styles.button}
                onClick={triggerFileSelect}
              >
                <UploadIcon className={styles.icon} />
              </IconButton>
            </div>
            <div
              className={[
                styles.chatSendAction,
                !chatBoxContent && styles.disabled,
              ].join(" ")}
            >
              <Button
                onClick={() => {
                  handleSendMessage(chatBoxContent);
                }}
              >
                {isFeedView ? "Create thread" : ""}
                <PaperPlaneIcon />
              </Button>
            </div>
          </div>
          <div className={[styles.sendButtonShelf].join(" ")}></div>
          <div>
            <Flex gap="2" className={styles.selectedFilesPreview}>
              {selectedFiles.map((img, index) => (
                <Flex
                  mb="2"
                  key={index}
                  style={{
                    position: "relative",
                  }}
                  className={styles.selectedFileContainer}
                >
                  <Flex>
                    <FilePreview img={img} />
                  </Flex>
                  <Button
                    className={styles.removeFileButton}
                    size="1"
                    variant="solid"
                    color="red"
                    radius="large"
                    onClick={() => handleFileRemove(index)}
                  >
                    <Cross2Icon />
                  </Button>
                </Flex>
              ))}
            </Flex>
            <input
              type="file"
              accept={ACCEPTED_FILE_TYPES}
              ref={fileInputRef}
              onChange={handleFileSelect}
              multiple
              style={{ display: "none" }}
            />
            {repliedToChatMessage && (
              <Box ml="4" mb="2">
                <ReplyToChatPreview
                  avatarId={repliedToChatMessage.author.avatar_id}
                  displayName={repliedToChatMessage.author.display_name}
                  onCancel={clearChatReply}
                >
                  {getCompactComponentsFromChatBlocks(
                    repliedToChatMessage.blocks,
                  )}
                </ReplyToChatPreview>
              </Box>
            )}
            <ChatBoxEditor
              placeholder={
                isFeedView ? FEED_VIEW_PLACEHOLDER : CHAT_VIEW_PLACEHOLDER
              }
              autofocus={!isChatBoxDisabled}
              onChange={handleRichTextChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onArrowUpFirstCharacter={handleArrowUp}
              onArrowDownLastCharacter={() => {
                similarThreadsRef.current?.focus();
                setHasSimilarThreadsOpen(true);
              }}
              onEnter={(editorOutput) => {
                handleSendMessage(editorOutput);
              }}
              onOpenEmojiPicker={() => setIsColonEmojiPickerVisible(true)}
              onImageUpload={handleFiles}
            />
          </div>

          {isColonEmojiPickerVisible && (
            <div
              style={{
                position: "absolute",
                bottom: "100%",
                left: 0,
              }}
            >
              <Picker
                theme={theme}
                autoFocus
                onClickOutside={() => handleColonEmojiClose(false)}
                onEmojiSelect={handleEmojiClick}
              />
            </div>
          )}
        </div>
        <TypingIndicator
          style={{
            color: "var(--slate-10)",
            bottom: "var(--space-1)",
            position: "absolute",
          }}
          names={typingUsers}
        />
      </div>
      {shouldShowSimilarThreads && (
        <Flex direction="column" className={styles.similarThreadsContainer}>
          <Flex
            align="center"
            className={styles.similarThreadsTrigger}
            onClick={toggleSimilarThreads}
            style={{
              cursor: hasSimilarThreads ? "pointer" : "initial",
              pointerEvents: hasSimilarThreads ? "initial" : "none",
            }}
          >
            <LightningBoltIcon
              className={[
                styles.icon,
                hasSimilarThreads ? styles.hasSimilarThreads : "",
              ].join(" ")}
            />
            <Text
              className={[
                styles.text,
                hasSimilarThreads ? styles.hasSimilarThreads : "",
              ].join(" ")}
              size="2"
              weight="medium"
            >
              Similar Threads
            </Text>
          </Flex>
          {hasSimilarThreads && (
            <Collapsible.Root
              open={hasSimilarThreadsOpen}
              onOpenChange={toggleSimilarThreads}
            >
              <Collapsible.Content className={styles.collapsibleContent}>
                <SimilarThreadsSelector
                  ref={similarThreadsRef}
                  onThreadOpen={handleOpenThread}
                  similarThreads={similarThreads}
                  onClose={handleCloseSimilarThreads}
                  isExpanded={hasSimilarThreadsOpen}
                  isFeedView={isFeedView}
                  handleChatBoxFocus={() => {
                    getChatBoxCommands()?.focus();
                  }}
                />
              </Collapsible.Content>
            </Collapsible.Root>
          )}
        </Flex>
      )}
    </div>
  );
};

export default ChatBox;
