import { IconType } from "@/app/components/icons/icon.type";

const NewThreadIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" />
    <path
      d="M10.99 11.74H4.06C4 11.74 3.96 11.7 3.96 11.64V2.1C3.96 2.05 4 2 4.06 2H10.99C11.27 2 11.49 1.78 11.49 1.5C11.49 1.22 11.27 1 10.99 1H4.06C3.45 1 2.96 1.49 2.96 2.1V3.12H2.1C1.49 3.12 1 3.61 1 4.22V13.76C1 14.37 1.49 14.86 2.1 14.86H8.43C9.04 14.86 9.53 14.37 9.53 13.76V12.74H10.99C11.27 12.74 11.49 12.52 11.49 12.24C11.49 11.96 11.27 11.74 10.99 11.74ZM8.53 13.76C8.53 13.81 8.49 13.86 8.43 13.86H2.1C2.05 13.86 2 13.82 2 13.76V4.22C2 4.17 2.04 4.12 2.1 4.12H2.96V11.65C2.96 12.26 3.45 12.75 4.06 12.75H8.52V13.77L8.53 13.76ZM14.32 6.87C14.32 7.15 14.1 7.37 13.82 7.37H11.49V9.7C11.49 9.98 11.27 10.2 10.99 10.2C10.71 10.2 10.49 9.98 10.49 9.7V7.37H8.16C7.88 7.37 7.66 7.15 7.66 6.87C7.66 6.59 7.88 6.37 8.16 6.37H10.49V4.04C10.49 3.76 10.71 3.54 10.99 3.54C11.27 3.54 11.49 3.76 11.49 4.04V6.37H13.82C14.1 6.37 14.32 6.59 14.32 6.87Z"
      fill="currentColor"
    />
  </svg>
);

export default NewThreadIcon;
