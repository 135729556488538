import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const ArrowLoopUpReverse = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1456 8.27064C10.1456 8.06354 9.97771 7.89564 9.77061 7.89564H8.0906C7.53434 7.89564 7.14651 7.89515 6.84822 7.86362C6.55663 7.8328 6.39424 7.77576 6.27372 7.69106C6.16744 7.61636 6.07489 7.52381 6.00019 7.41752C5.91549 7.29701 5.85844 7.13461 5.82762 6.84303C5.79609 6.54474 5.79561 6.15691 5.79561 5.60064L5.79561 2.08847L8.06544 4.35831C8.21189 4.50476 8.44932 4.50476 8.59577 4.35831C8.74222 4.21186 8.74222 3.97443 8.59577 3.82798L5.81327 1.04548C5.59182 0.824033 5.23439 0.824033 5.01294 1.04548L2.23044 3.82798C2.08399 3.97442 2.08399 4.21186 2.23044 4.35831C2.37689 4.50476 2.61432 4.50476 2.76077 4.35831L5.04561 2.07347V5.62171C5.0456 6.15195 5.04559 6.57952 5.08178 6.92187C5.11915 7.27544 5.1985 7.58117 5.38657 7.84878C5.51108 8.02593 5.66532 8.18017 5.84247 8.30467C6.11008 8.49275 6.4158 8.5721 6.76938 8.60947C7.11172 8.64565 7.53929 8.64565 8.06953 8.64564H9.77061C9.97771 8.64564 10.1456 8.47775 10.1456 8.27064Z"
      fill="currentColor"
      fillOpacity="0.624"
    />
  </svg>
);

export default ArrowLoopUpReverse;
