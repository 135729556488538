import React, { useState } from "react";
import { Button, Flex, Separator, Text } from "@radix-ui/themes";
import { useAppStore } from "@/app/store";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";
import NewThreadIcon from "../icons/NewThreadIcon";
import { TrashIcon, Cross1Icon } from "@radix-ui/react-icons";
import ConfirmationDialog from "../ConfirmationDialog";
import useChats from "@/app/hooks/useChats";
import { useApp } from "@/app/hooks/useApp";

type Confirmation = "create" | "delete" | null;
const CONFIRMATION_DIALOG = {
  create: {
    title: "Create a new thread from selected chats?",
    primaryLabel: "Create",
  },
  delete: {
    title: "Permanently delete chats that you have permissions to?",
    primaryLabel: "Delete",
  },
};

export default function ChatActions() {
  const { currentPath: threadId, navigateToPath } = useShallowNavigation();

  const { featureToggles } = useApp();
  const { canCreateThreadFromChat } = featureToggles;
  const selectedChats = useAppStore(
    (state) => state.selectedChatsByThreadId?.[threadId || ""] ?? [],
  );
  const currentThreadChatIds = useAppStore(
    (state) =>
      state.chatsByThreadId?.[threadId || ""]?.map((chat) => chat.id) ?? [],
  );
  const clearSelectedChats = useAppStore((state) => state.clearSelectedChats);
  const setSelectedChats = useAppStore((state) => state.setSelectedChats);
  const toggleSelectedChatMessage = useAppStore(
    (state) => state.toggleSelectedChatMessage,
  );

  const { deleteChatMessage, forkChats } = useChats();

  const [confirmationType, setConfirmationType] = useState<Confirmation>(null);

  if (selectedChats.length <= 0 || !threadId) {
    return null;
  }

  const handleSelectAll = () => {
    setSelectedChats(threadId as string, currentThreadChatIds);
  };

  const handleCreate = async () => {
    try {
      const { thread_id: threadId } = await forkChats({
        chatIds: selectedChats,
      });
      navigateToPath(threadId);
    } catch (error) {
      console.error(error);
    } finally {
      clearSelectedChats();
    }
  };

  const handleDelete = async () => {
    await Promise.all(
      selectedChats.map((messageId) =>
        deleteChatMessage({ messageId, threadId }).then((response) => {
          // @ts-expect-error
          if (response.ok) {
            toggleSelectedChatMessage(threadId, messageId);
          }
        }),
      ),
    );
  };

  const handleConfirm = async () => {
    try {
      switch (confirmationType) {
        case "create":
          await handleCreate();
          break;
        case "delete":
          await handleDelete();
          break;
      }
    } catch (error) {
      console.error("error :", error);
    }
    setConfirmationType(null);
  };

  const handleCancel = (event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setConfirmationType(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      setConfirmationType(null);
    }
  };

  return (
    <>
      <Flex
        p="4"
        gap="3"
        align="center"
        justify="center"
        style={{
          borderRadius: "var(--radius-4)",
          border: "1px solid var(--slate-a5)",
          backdropFilter: "blur(10px)",
          width: "max-content",
          background: "var(--color-surface)",
          boxShadow: "var(--shadow-5)",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "var(--space-6)",
          zIndex: 1000,
        }}
        onKeyDown={handleKeyDown}
      >
        <Flex gap="3" align="center">
          <Text size="2">Manage Chats</Text>
          {/* Show create new thread option only for non-slack organisation */}
          {canCreateThreadFromChat && (
            <Button
              size="2"
              variant="outline"
              onClick={() => setConfirmationType("create")}
            >
              <NewThreadIcon />
              Create new thread
            </Button>
          )}
          <Button
            size="2"
            variant="outline"
            style={{ color: "var(--red-11)" }}
            onClick={() => setConfirmationType("delete")}
          >
            <TrashIcon />
            Delete selected
          </Button>
        </Flex>
        <Separator orientation="vertical" />
        <Flex gap="3" align="center">
          <Button size="1" variant="ghost" onClick={handleSelectAll}>
            Select all
          </Button>
          <Button size="1" variant="ghost" onClick={clearSelectedChats}>
            Cancel
            <Cross1Icon width={12} height={12} />
          </Button>
        </Flex>
      </Flex>
      {confirmationType && (
        <ConfirmationDialog
          title={CONFIRMATION_DIALOG[confirmationType].title}
          primaryLabel={CONFIRMATION_DIALOG[confirmationType].primaryLabel}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
}
