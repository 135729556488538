import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const ArrowRightCenter = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.625 2.25C2.83211 2.25 3 2.41789 3 2.625V4.305C3 4.86126 3.00049 5.2491 3.03202 5.54739C3.06284 5.83897 3.11988 6.00137 3.20458 6.12188C3.27928 6.22817 3.37183 6.32072 3.47812 6.39542C3.59863 6.48012 3.76103 6.53716 4.05261 6.56798C4.35091 6.59951 4.73874 6.6 5.295 6.6H8.80717L6.53734 4.33016C6.39089 4.18372 6.39089 3.94628 6.53734 3.79984C6.68378 3.65339 6.92122 3.65339 7.06767 3.79984L9.85016 6.58233C10.0716 6.80378 10.0716 7.16122 9.85016 7.38266L7.06767 10.1652C6.92122 10.3116 6.68378 10.3116 6.53734 10.1652C6.39089 10.0187 6.39089 9.78128 6.53734 9.63483L8.82217 7.35H5.27393C4.74369 7.35001 4.31612 7.35001 3.97378 7.31383C3.6202 7.27645 3.31447 7.19711 3.04687 7.00903C2.86972 6.88453 2.71547 6.73028 2.59097 6.55313C2.40289 6.28553 2.32355 5.9798 2.28617 5.62622C2.24999 5.28388 2.24999 4.85631 2.25 4.32608L2.25 2.625C2.25 2.41789 2.41789 2.25 2.625 2.25Z"
      fill="currentColor"
      fillOpacity="0.624"
    />
  </svg>
);

export default ArrowRightCenter;
