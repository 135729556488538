import { useEffect } from "react";
import styles from "@/app/components/ChatBox/ChatBoxEditor.module.scss";
import Document from "@tiptap/extension-document";
import { HardBreak } from "@tiptap/extension-hard-break";
import { History } from "@tiptap/extension-history";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { Editor, EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import { MentionList } from "../MentionList";

import { PluginKey } from "prosemirror-state";
import { EditorCommands } from "@/app/store/ChatBox.store";
import { useAppStore } from "@/app/store";
import Link from "@tiptap/extension-link";
import { getMentions } from "@/app/services/mentions";
import { StructUser } from "@/app/types/Thread.type";
import { StructChannel } from "@/app/types/Channel.type";

interface ChatBoxEditorProps {
  onChange: (content: string) => void;
  placeholder?: string;
  onFocus: () => void;
  onBlur: () => void;
  onArrowUpFirstCharacter: () => void;
  onArrowDownLastCharacter: () => void;
  onEnter: (editorOutput: string) => void;
  onOpenEmojiPicker: () => void;
  onImageUpload: (images: File[]) => Promise<void>;
  autofocus?: boolean;
}

const extractTextWithNewlines = (state: Editor["state"]) => {
  let textWithNewlines = "";
  state.doc.forEach((block) => {
    block.forEach((node) => {
      if (node.type.name === "text") {
        const linkMark = node.marks.find((mark) => mark.type.name === "link");
        textWithNewlines += linkMark ? `<${linkMark.attrs.href}>` : node.text;
      }
      if (node.type.name === "hardBreak") {
        textWithNewlines += "\n";
      }
      if (node.type.name === "mention") {
        const mentionId = node.attrs.id;
        textWithNewlines += `\<@${mentionId}\>`;
      }
      if (node.type.name === "tag") {
        const mentionId = node.attrs.id;
        textWithNewlines += `<#${mentionId}>`;
      }
    });
  });

  return textWithNewlines;
};

const USERS_SEARCH_RESULT_LIMIT = 5;
const CHANNELS_SEARCH_RESULT_LIMIT = 5;

const ChatBoxEditor = ({
  onChange,
  placeholder,
  onFocus,
  onBlur,
  onArrowUpFirstCharacter,
  onArrowDownLastCharacter,
  onEnter,
  onOpenEmojiPicker,
  onImageUpload,
  autofocus = true,
}: ChatBoxEditorProps) => {
  const sessionUserId = useAppStore((state) => state.session?.user?.id);
  const tags = useAppStore((state) => state.tags);
  const initialContent = useAppStore((state) => state.initialContent);
  const setChatBoxCommands = useAppStore((state) => state.setChatBoxCommands);

  const editor = useEditor(
    {
      content: initialContent,
      editorProps: {
        handlePaste: (view, event) => {
          const { clipboardData } = event;

          if (!clipboardData) {
            return false;
          }

          const images = Array.from(clipboardData?.files)?.filter((file) =>
            /image/i.test(file.type),
          );

          if (images.length > 0) {
            onImageUpload(images);
            return;
          }

          const pastedText = clipboardData.getData("text/plain");
          const containsNewLines = pastedText.includes("\n");

          if (containsNewLines) {
            event.preventDefault();

            const createTextFragment = (textPart: string) => {
              if (!textPart) {
                return [];
              }

              const textNode = view.state.schema.text(textPart);
              const breakNode = view.state.schema.node("hardBreak");

              return [textNode, breakNode];
            };

            const textFragments = pastedText
              .split("\n")
              .flatMap(createTextFragment);

            const endsWithHardBreak =
              textFragments.length &&
              textFragments[textFragments.length - 1].type.name === "hardBreak";

            if (endsWithHardBreak) {
              textFragments.pop();
            }

            const hasFragmentsToInsert = textFragments.length > 0;
            if (hasFragmentsToInsert) {
              const transaction = view.state.tr.insert(
                view.state.selection.from,
                textFragments,
              );
              view.dispatch(transaction);
              return true;
            }
          }

          return false;
        },

        attributes: { class: "editor" },
        handleKeyDown: (props, event) => {
          if (event.key === ":") {
            const { from } = props.state.selection;
            if (from <= 0) {
              return;
            }

            const prevChar = props.state.doc.textBetween(from - 1, from);
            if (prevChar !== " " && from !== 1) {
              return;
            }

            event.preventDefault();
            onOpenEmojiPicker();
            return true;
          }

          const mentionIsActive = props.state.plugins.some(
            (plugin) =>
              // @ts-ignore
              plugin.key.startsWith("mention$") &&
              plugin.getState(props.state).active,
          );

          if (mentionIsActive) {
            return;
          }

          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();

            if (event.shiftKey) {
              return;
            }
            const editorOutput = extractTextWithNewlines(props.state);
            if (!editorOutput.trim()) {
              return true;
            }
            onEnter(editorOutput);
            return true;
          }

          const { from, to } = props.state.selection;

          if (event.key === "ArrowUp" && from === 1 && to === 1) {
            onArrowUpFirstCharacter();
            return;
          }

          const docLength = props.state.doc.content.size;

          if (
            event.key === "ArrowDown" &&
            from + 1 === docLength &&
            to + 1 === docLength
          ) {
            onArrowDownLastCharacter();
            return;
          }
        },
      },
      autofocus,
      extensions: [
        Link.configure({
          openOnClick: false,
          validate: (href) => /^(https?:\/\/|www\.)/.test(href),
        }),
        Placeholder.configure({
          placeholder,
          includeChildren: true,
        }),
        Document,
        Paragraph.configure({
          HTMLAttributes: { class: "paragraph" },
        }),
        Text,
        HardBreak,
        History,
        Mention.extend({
          name: "mention",
        }).configure({
          HTMLAttributes: { class: "mentionNode" },
          suggestion: {
            char: "@",
            pluginKey: new PluginKey("mention"),
            allowSpaces: false,
            allowedPrefixes: ["-", " "],
            items: ({ query }) => {
              return new Promise(async (resolve) => {
                const authenticatedUserId = sessionUserId;
                if (!authenticatedUserId) return resolve([]);

                const mentions = await getMentions(query, true);

                const users: StructUser[] =
                  mentions?.users?.slice(0, USERS_SEARCH_RESULT_LIMIT) ?? [];
                const channels: StructChannel[] =
                  mentions?.channels?.slice(0, CHANNELS_SEARCH_RESULT_LIMIT) ??
                  [];

                resolve([
                  ...channels.map((c) => ({ ...c, type: "channel" })),
                  ...users.map((u) => ({ ...u, type: "user" })),
                ]);
              });
            },
            render: () => {
              let reactRenderer: ReactRenderer | null;

              return {
                onStart: (props) => {
                  reactRenderer = new ReactRenderer(MentionList, {
                    props: { ...props, type: "mention", showSearch: true },
                    editor: props.editor,
                  });
                },

                onUpdate(props) {
                  reactRenderer?.updateProps(props);
                },

                onKeyDown(props) {
                  if (props.event.key === "Escape") {
                    reactRenderer?.destroy();
                    return true;
                  }

                  return (reactRenderer?.ref as any)?.onKeyDown(props);
                },

                onExit() {
                  reactRenderer?.destroy();
                  reactRenderer = null;
                },
              };
            },
          },
        }),
        Mention.extend({
          name: "tag",
        }).configure({
          HTMLAttributes: { class: "tagNode" },
          suggestion: {
            char: "#",
            pluginKey: new PluginKey("mention"),
            allowSpaces: false,
            allowedPrefixes: ["-", " "],
            items: async ({ query }) => {
              const filteredTags = tags
                .filter((tag) =>
                  tag.tag.toLowerCase().startsWith(query.toLowerCase()),
                )
                .slice(0, 5);

              return filteredTags.map((tag) => ({ ...tag, type: "tag" }));
            },
            render: () => {
              let reactRenderer: ReactRenderer | null;

              return {
                onStart: (props) => {
                  reactRenderer = new ReactRenderer(MentionList, {
                    props: { ...props, type: "tag" },
                    editor: props.editor,
                  });
                },

                onUpdate(props) {
                  reactRenderer?.updateProps(props);
                },

                onKeyDown(props) {
                  if (props.event.key === "Escape") {
                    reactRenderer?.destroy();
                    reactRenderer = null;
                    return true;
                  }

                  return (reactRenderer?.ref as any)?.onKeyDown(props);
                },

                onExit() {
                  reactRenderer?.destroy();
                  reactRenderer = null;
                  return true;
                },
              };
            },
          },
        }),
      ],
    },
    [],
  );

  useEffect(() => {
    if (!editor) {
      return;
    }

    const commands: EditorCommands = {
      insertMention: (mentionData) => {
        editor.commands.insertContent({
          type: "mention",
          attrs: {
            id: mentionData.id,
            label: mentionData.label,
          },
        });
      },
      focus: () => {
        if (editor) {
          editor.commands.focus("end");
        }
      },
      blur: () => {
        if (editor) {
          editor.commands.blur();
        }
      },
      insertContent: (content: string) => {
        if (editor) {
          editor.commands.insertContent(content);
        }
      },
      clearContent: () => {
        if (editor) {
          editor.commands.clearContent();
        }
      },
      insertCharacter: (character: string) => {
        const atCharacter = character;

        if (editor) {
          const content = editor.getJSON();
          const lastNode = content.content?.slice(-1)[0];
          if (lastNode && lastNode.type === "paragraph" && lastNode.content) {
            // @ts-ignore
            const lastChar = lastNode.content[0].text;

            if (lastChar === atCharacter) {
              editor.commands.insertContent(atCharacter.slice(1));
              return;
            }
          }

          editor.commands.insertContent(atCharacter);
        }
      },
    };
    setChatBoxCommands(commands);
  }, [editor]);

  useEffect(() => {
    if (editor !== null && placeholder !== "") {
      editor.extensionManager.extensions.filter(
        (extension) => extension.name === "placeholder",
      )[0].options["placeholder"] = placeholder;

      editor.view.dispatch(editor.state.tr);
    }
  }, [editor, placeholder]);

  useEffect(() => {
    if (editor) {
      editor.on("update", () => {
        const newContent = extractTextWithNewlines(editor.state);
        onChange(newContent);
      });

      editor.on("focus", onFocus);
      editor.on("blur", onBlur);
    }

    return () => {
      if (editor) {
        editor.off("update");
        editor.off("focus");
        editor.off("blur");
      }
    };
  }, [editor, onChange]);

  if (!editor) {
    return <div className={styles.chatBoxEditorSkeleton}></div>;
  }

  return <EditorContent editor={editor} className={styles.chatBoxEditor} />;
};

export default ChatBoxEditor;

ChatBoxEditor.displayName = "ChatBoxEditor";
