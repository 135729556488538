import { Flex, Heading, Text, Badge, Box } from "@radix-ui/themes";
import React from "react";
import ArrowLoopUpReverse from "../icons/ArrowLoopUpReverse";
import ArrowRightCenter from "../icons/ArrorRightCenter";

import FeedItemFooter from "../FeedItem/FeedItemFooter";
import { StructThread } from "@/app/types/Thread.type";
import { useAppStore } from "@/app/store";
import { formatTimeAgo } from "@/app/utils/timeUtils";
import { DateTime } from "luxon";

interface Props {
  threadId: string;
  title: string;
  summary: string;
  reactions: StructThread["reactions"];
  messageCount: number;
  unreadMessageCount: number;
  createdDate: string;
  lastUpdated: number;
  bits: StructThread["bits"];
}

const SimilarThreadCard = ({
  threadId,
  title,
  summary,
  reactions,
  messageCount,
  unreadMessageCount,
  createdDate,
  lastUpdated,
  bits,
}: Props) => {
  const isResolved = bits?.thread_resolved;

  const currentUserId = useAppStore((state) => state.session?.user?.id);
  const typingUsersByThread = useAppStore(
    (state) => state.typingUsersByThreadId?.[threadId],
  );
  const typingUserNamesById = useAppStore((state) => state.typingUserNamesById);
  const typingUsers =
    typingUsersByThread
      ?.filter((id) => id !== currentUserId)
      ?.map((id) => typingUserNamesById?.[id]) ?? [];

  return (
    <Flex gap="4" direction="column">
      <Flex gap="2" align="center">
        <Badge variant="soft" size="1" color="gray">
          <Flex gap="2" align="center">
            <ArrowLoopUpReverse />
            up
          </Flex>
        </Badge>
        <Text size="1" style={{ color: "var(--slate-9)" }}>
          Back to message
        </Text>
      </Flex>
      <Flex direction="column" gap="3">
        <Heading
          weight="medium"
          as="h2"
          size="2"
          style={{ color: "var(--slate-12)" }}
        >
          {title}
        </Heading>

        <Text size="2" style={{ color: "var(--slate-11)" }}>
          {summary}
        </Text>
      </Flex>
      <Flex
        align="center"
        justify="between"
        gap="8"
        style={{
          overflow: "hidden",
        }}
      >
        <FeedItemFooter
          reactions={reactions}
          messageCount={messageCount}
          num_unread={unreadMessageCount}
          createdDate={createdDate}
          lastUpdated={formatTimeAgo(DateTime.fromSeconds(lastUpdated))}
          isResolved={isResolved}
          typingUsers={typingUsers}
        />
        <Flex gap="2" align="center">
          <Box
            display={{
              initial: "none",
              xs: "inline-block",
            }}
          >
            <Text
              size="1"
              style={{ color: "var(--slate-9)", whiteSpace: "nowrap" }}
            >
              Go to thread
            </Text>
          </Box>
          <Badge variant="soft" size="1" color="gray">
            <Flex gap="2" align="center">
              <ArrowRightCenter />
              enter
            </Flex>
          </Badge>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SimilarThreadCard;
