import React, { useRef } from "react";
import { throttle, ThrottleSettings } from "lodash";

interface Props {
  (func: any): any;
}

const useThrottle = (func: Props, time = 1000, options?: ThrottleSettings) => {
  const debouncedFunc = useRef(throttle(func, time, options)).current;

  return debouncedFunc;
};

export default useThrottle;
