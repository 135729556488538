import React, { useState, useEffect, forwardRef } from "react";
import { StructThread, StructThreadResponse } from "@/app/types/Thread.type";
import styles from "./SimilarThreadsSelector.module.scss";
import { Card, Flex, Text } from "@radix-ui/themes";
import { FileTextIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import SimilarThreadCard from "./SimilarThreadCard";

interface SimilarThreadsSelectorProps {
  similarThreads: StructThread[];
  onThreadOpen: (threadId: StructThreadResponse["id"]) => void;
  onClose: () => void;
  isExpanded: boolean;
  onTextAreaClick?: () => void;
  isFeedView: boolean;
  handleChatBoxFocus: () => void;
}

const SimilarThreadsSelector = forwardRef<
  HTMLDivElement,
  SimilarThreadsSelectorProps
>(
  (
    {
      similarThreads,
      onThreadOpen,
      onClose,
      isExpanded,
      isFeedView,
      handleChatBoxFocus,
    },
    ref,
  ) => {
    const [selectedThreadIndex, setSelectedThreadIndex] = useState<
      null | number
    >(null);
    const [hasMouseMovedSinceOpen, setHasMouseMovedSinceOpen] = useState(false);

    const [lastInteractionKeyboard, setLastInteractionKeyboard] =
      useState(false);

    const isElementInViewport = (el: HTMLElement, parent: HTMLElement) => {
      const rect = el.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();
      return rect.left >= parentRect.left && rect.right <= parentRect.right;
    };

    useEffect(() => {
      if (isExpanded && !isFeedView) {
        if (ref && "current" in ref && ref.current) {
          ref.current.focus();
        }
      }
    }, [isExpanded, isFeedView, ref]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      setLastInteractionKeyboard(true);
      let newIndex: number | null = selectedThreadIndex;

      if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
        e.preventDefault();
      }

      if (e.key === "ArrowLeft" && selectedThreadIndex !== null) {
        newIndex = newIndex === 0 ? 0 : newIndex! - 1;
      }

      if (e.key === "ArrowRight" && selectedThreadIndex !== null) {
        newIndex =
          newIndex === similarThreads.length - 1 ? newIndex : newIndex! + 1;
      }

      if (e.key === "Enter" && selectedThreadIndex !== null) {
        e.preventDefault();
        onThreadOpen(similarThreads[selectedThreadIndex].id);
        onClose();
        return;
      }

      if (e.key === "ArrowUp") {
        setSelectedThreadIndex(null);

        setTimeout(() => {
          handleChatBoxFocus();

          if (!isFeedView) {
            onClose();
          }
        }, 0);
        return;
      }

      if (newIndex !== null && ref && "current" in ref && ref.current) {
        const container = ref.current;
        const button = container.children[newIndex] as HTMLElement;

        if (!isElementInViewport(button, container)) {
          if (
            button.getBoundingClientRect().left <
            container.getBoundingClientRect().left
          ) {
            container.scrollLeft -=
              container.getBoundingClientRect().left -
              button.getBoundingClientRect().left;
          } else {
            container.scrollLeft +=
              button.getBoundingClientRect().right -
              container.getBoundingClientRect().right;
          }
        }
      }

      setSelectedThreadIndex(newIndex);
    };

    const handleFocus = () => {
      if (selectedThreadIndex === null) {
        setSelectedThreadIndex(0);
      }
    };

    const handleBlur = () => {
      setSelectedThreadIndex(null);
    };

    const handleThreadClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      id: StructThread["id"],
      index: number,
    ) => {
      event.stopPropagation();
      setSelectedThreadIndex(index); // set the index here
      onThreadOpen(id);
    };

    const handleMouseMove = () => {
      if (!hasMouseMovedSinceOpen) {
        setHasMouseMovedSinceOpen(true);
      }
      setLastInteractionKeyboard(false);
    };

    const handleMouseOver = (index: number) => {
      if (hasMouseMovedSinceOpen && !lastInteractionKeyboard) {
        setSelectedThreadIndex(index);
      }
    };

    const selectedThread =
      selectedThreadIndex !== null ? similarThreads[selectedThreadIndex] : null;

    return (
      <Flex direction="column">
        <div
          className={styles.similarThreadsSelector}
          tabIndex={0}
          ref={ref}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            if (!lastInteractionKeyboard) {
              setSelectedThreadIndex(null);
            }
          }}
        >
          {similarThreads.map((thread, index) => {
            const isSelected = selectedThreadIndex === index;

            return (
              <button
                key={thread.id}
                style={{
                  maxWidth: "17rem",
                  minWidth: "17rem",
                }}
                className={`${styles.similarThreadButton} ${
                  isSelected ? styles.selected : ""
                }`}
                onClick={(event) => handleThreadClick(event, thread.id, index)}
                onMouseOver={() => handleMouseOver(index)}
              >
                <Flex gap="2" justify="start" grow="1">
                  <FileTextIcon className={styles.fileTextIcon} />
                  <Text
                    style={{
                      color: "var(--slate-12)",
                      textAlign: "left",
                    }}
                    size="1"
                  >
                    {thread.subject}
                  </Text>
                </Flex>
                {isSelected && <ArrowRightIcon className={styles.arrowIcon} />}
              </button>
            );
          })}
          {selectedThread && (
            <div className={styles.similarThreadModal}>
              <SimilarThreadCard
                threadId={selectedThread.id}
                title={selectedThread.subject}
                summary={selectedThread.summaryText}
                lastUpdated={selectedThread.updated_at}
                createdDate={selectedThread.createdDate}
                reactions={selectedThread.reactions}
                bits={selectedThread.bits}
                messageCount={selectedThread.num_chats}
                unreadMessageCount={0}
              />
            </div>
          )}
        </div>
      </Flex>
    );
  },
);

export default SimilarThreadsSelector;

SimilarThreadsSelector.displayName = "SimilarThreadsSelector";
