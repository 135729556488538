import { useState, useEffect } from "react";

const useTimedCallout = (
  initialVisibility: boolean = false,
  duration: number = 3000,
) => {
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(initialVisibility);
  let timerId: NodeJS.Timeout;

  useEffect(() => {
    if (isCalloutVisible) {
      timerId = setTimeout(() => {
        setIsCalloutVisible(false);
      }, duration);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isCalloutVisible, duration]);

  const showCallout = () => {
    setIsCalloutVisible(true);
  };

  const hideCallout = () => {
    setIsCalloutVisible(false);
    clearTimeout(timerId);
  };

  return [isCalloutVisible, showCallout, hideCallout] as const;
};

export default useTimedCallout;
